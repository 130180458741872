import { version } from '../../package.json';

export const environment = {
  production: false,
  dev: true,
  uat: false,
  version: version,

  appId: "anexos-gui",
  empresas: [
    { id: 1, domain: 'dev.axkansoluciones.com' },
    { id: 2, domain: 'dev.sbsynergy.mx' },
    { id: 3, domain: 'dev.habitataxkan.com.mx' }
  ],

  msAnexo: "https://anexos.dev-ms.axkans.net/anexos/anexo",
  msEstacionalidad: "https://anexos.dev-ms.axkans.net/anexos/estacionalidad",
  msCliente: "https://cliente.dev-ms.axkans.net/clientes/cliente",
  msEmpresa: "https://cliente.dev-ms.axkans.net/clientes/empresa-cliente",
  msColaboradorExterno: "https://anexos.dev-ms.axkans.net/anexos/colaborador-externo",
  msTipoColabExterno: "https://anexos.dev-ms.axkans.net/anexos/tipo-colaborador-externo",
  msEmpleado: "https://rh.dev-ms.axkans.net/rh/empleado/ps",
  msDistribucionExt: "https://anexos.dev-ms.axkans.net/anexos/distribucion-externa",
  msDistribucionInt: "https://anexos.dev-ms.axkans.net/anexos/distribucion-interna",
  msDepositos: "https://nomina.dev-ms.axkans.net/nomina/depositos",
  msConciliacion: "https://anexos.dev-ms.axkans.net/anexos/conciliacion",
  msEstatusAnexo: "https://anexos.dev-ms.axkans.net/anexos/estatus-anexos",
  msFactura: "https://factura.dev-ms.axkans.net/facturas/factura",
  msParticipante: "https://anexos.dev-ms.axkans.net/anexos/participante",
  msGrafica: "https://anexos.dev-ms.axkans.net/anexos/grafica",
  msBono: "https://anexos.dev-ms.axkans.net/anexos/bono",
  msMontoVirtual: "https://anexos.dev-ms.axkans.net/anexos/monto-virtual",
  msDistMontoVirtual: "https://anexos.dev-ms.axkans.net/anexos/distribucion-monto-virtual",
  msDistribucion: "https://anexos.dev-ms.axkans.net/anexos/distribucion",
  msReserva: "https://anexos.dev-ms.axkans.net/anexos/reserva",
  msSegReserva: "https://anexos.dev-ms.axkans.net/anexos/seguimiento-reserva",
  msEstatusEstacionalidad: "https://anexos.dev-ms.axkans.net/anexos/estatus-estacionalidad",
  msOrganigrama: "https://rh.dev-ms.axkans.net/rh/organigrama",

  porcentajeIva: 16,

  sprofile: "https://sec.dev-ms.axkans.net/autentica/profile",
  theme: {
    default: 'https://static.dev-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }

}
